import FormsService from '../../services/forms-service.js'
import MultipleFormsService from '../../services/multiple/forms-service.js'

export default {
  namespaced: true,
  state: {
    formData: [],
    currentPageIndex: 0,
    hasSignerForm: false,
    hasPendingSignerForm: false,
    formToken: "",
    formAnswers: {}
  },
  mutations: {
    updateFormData(state, payload) {
      state.formData = payload;
      console.log('updateFormData', state.formData); // Debugging
    },
    setFormToken(state, token) {
      state.formToken = token;
      localStorage.setItem('formToken', token);
      console.log('setFormToken', state.formToken); // Debugging
    },
    updateCurrentPage(state, newIndex) {
      state.currentPageIndex = newIndex;
      console.log('updateCurrentPage', state.currentPageIndex); // Debugging
    },
    setHasSignerForm(state, payload) {
      state.hasSignerForm = payload;
      console.log('setHasSignerForm mutation', state.hasSignerForm); // Debugging
    },
    setHasPendingSignerForm(state, payload) {
      state.hasPendingSignerForm = payload;
      console.log('setHasPendingSignerForm mutation', state.hasPendingSignerForm); // Debugging
    },
    SET_FORM_DATA(state, formData) {
      state.formData = formData;
      console.log('SET_FORM_DATA', state.formData); // Debugging
    },
    SET_FORM_ANSWERS(state, answers) {
      state.formAnswers = answers;
      console.log('SET_FORM_ANSWERS', state.formAnswers); // Debugging
    }
  },
  actions: {
    async getFormDetails({ commit }, payload) {
      try {
        const response = await FormsService.getFormStructure(payload.requestId, payload.token, payload.authId);
        commit('updateFormData', response.data);
      } catch (error) {
        console.error('Error fetching form details:', error);
        throw error;
      }
    }, 
     async getFormStatus({ commit }, payload) {
      const response = await FormsService.getFormStatus(payload.requestId, payload.token, payload.authId);
      commit('setHasSignerForm', response.data.hasSignerForm);
      commit('setHasPendingSignerForm', response.data.hasPendingSignerForm);
    },

    async getMultipleFormStatus({ commit }, payload) {
      const response = await MultipleFormsService.getFormStatus(payload.requestId, payload.token, payload.authId);
      commit('setHasSignerForm', response.data.hasSignerForm);
      commit('setHasPendingSignerForm', response.data.hasPendingSignerForm);
    },
  
    async addFormToken({ commit }, token) {
      commit('setFormToken', token);
    },
    saveFormAnswers({ commit }, answers) {
      commit('SET_FORM_ANSWERS', answers);
    },
    loadFormAnswers({ commit }) {
      const savedAnswers = JSON.parse(localStorage.getItem('formAnswers')) || {};
      commit('SET_FORM_ANSWERS', savedAnswers);
    },
    setHasSignerForm({ commit }, payload) {
      commit('setHasSignerForm', payload)
    },
    setHasPendingSignerForm({ commit }, payload) {
      commit('setHasPendingSignerForm', payload)
    },
  },
  getters: {
    hasSignerForm: state => state.hasSignerForm,
    hasPendingSignerForm: state => state.hasPendingSignerForm,
    getFormToken(state) {
      return state.formToken;
    },
    formData: state => state.formData,
    formAnswers: state => state.formAnswers
  }
};


export const baseURL = `${process.env.VUE_APP_API_URL}`+`${process.env.VUE_APP_VERSION}`+'/';
export const baseURLWithoutVersion = process.env.VUE_APP_API_URL;

export const getAccessToken = () => {
    let accessToken = localStorage.getItem("auth_user_token");

    if (localStorage.getItem("type") && ['ONETIME', 'ONETIMEWITHID'].includes(localStorage.getItem("type"))) {
        accessToken = localStorage.getItem("access_token");
    }

    return accessToken;
}

import axios from "axios";
import { HTTP } from "@/services/http-common";
import { baseURL } from "../config/setting";


export const SignaturesService = {
  authCheck(id, token, authId) {
      let url = `${baseURL}signatures/${id}/`;

      // Solo agregar authid si está definido y no es nulo
      if (authId) {
        url += `?authid=${authId}`;
      }
    return axios.get(url, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });
  },

  getSignatureRequestDataAuth(id, citizenId, isAuthCitizenId = false, token = null) {
    if (isAuthCitizenId) {
      return HTTP.get(`signatures/${id}/?authid=${citizenId}`);
    }else{
      return axios.get(baseURL + `signatures/${id}/?authid=${citizenId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      });
    }
  },

getSignatureRequestDocument(id) {
    let url = `signatures/${id}/document/`;

    // Obtener token de localStorage según prioridad
    let token = localStorage.getItem("auth_user_token") || localStorage.getItem("access_token") || null;
    console.log("Token: " + token);

    // Obtener authId solo si está presente
    let authId = localStorage.getItem("auth_id");
    if (authId) {
        url += `?authid=${authId}`;
    }

    // Si no hay token, evitar enviar la autorización
    let headers = {
        Accept: "application/pdf",
    };

    if (token) {
        headers.Authorization = `Token ${token}`;
    }

    return HTTP.get(url, {
        responseType: "arraybuffer",
        headers: headers,
    });
},


  sendOtp(requestId, data, token) {
    console.log ("data" + data);
    console.log ("this.token" + token);
    return axios.post(`${baseURL}signatures/${requestId}/sign/`, data, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });
  
  },



sendMultipleOtp(token) {
    console.log("Enviando código OTP para firma múltiple con token: " + token)
    return axios.post(`${baseURL}signatures/otp/`, null, { // 👈 Enviamos `{}` si no hay body
        headers: {
            Authorization: `Token ${token}`,
//            Accept: "application/json",
//            "Content-Type": "application/json", // 👈 Agregar esto si el servidor lo requiere
        },
    });
},


  sendOtpOneTimeAuth(requestId, data, token, authId){
    return axios.post(`${baseURL}signatures/${requestId}/sign/?authid=${authId}`, data, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });
  },


  signDocument(requestId, data) {
    return HTTP.post(`signatures/${requestId}/sign/`, data);
  },

  rejectDocument(requestId, data) {
    return HTTP.post(`signatures/${requestId}/reject/`, data);
  },
/**
  getSignatureRequestData(id, authId = null) {
    return HTTP.get(`signatures/${id}/?authid=${authId}`);
  },
**/
  getSignatureRequestData(id, authId = null) {
      let url = `signatures/${id}/`;

      // Solo agrega authid si tiene un valor válido
      if (authId) {
          url += `?authid=${authId}`;
      }
      return HTTP.get(url);
  },

  login(username, password) {
    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token, "utf8").toString("base64");
    return axios.get(baseURL + `users/${username}/`, {
      headers: {
        Authorization: `Basic ${encodedToken}`,
        Accept: "application/json",
      },
    });
  },

  signCertificateSignaturesDocument(apiToken, data, signatureId) {
    return axios.post(`${baseURL}signatures/${signatureId}/sign/`, data, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },

  getCertificateSignatureRequests(token, params) {
    return axios.get(baseURL + `signatures/`, {
      params: params,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

getCertificateSignatureRequestDocument(requestId, token, authId) {
    // Definir la URL base
    let url = `${baseURL}signatures/${requestId}/document/`;

    // Agregar authId solo si tiene un valor válido
    if (authId) {
        url += `?authid=${authId}`;
    }

    // Hacer la petición con la URL correcta
    return axios.get(url, {
        responseType: "arraybuffer",
        headers: {
            Authorization: `Token ${token}`,
            Accept: "application/pdf",
        },
    });
},

    getCertificateSignatureRequestDocumentUser(requestId, token) {
      return axios.get(
        baseURL + `signatures/${requestId}/`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Token ${token}`,
            Accept: "application/pdf",
          },
        }
      );
    },

  rejectCertificateDocument(apiToken, data, signatureId) {
    return axios.post(baseURL + `signatures/${signatureId}/reject/`, data, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },

  getCertificateSignatureDocument(apiToken, signatureId, authId = null) {
    let data = null;
    let url = `${baseURL}signatures/${signatureId}/sign/`;
    if (authId!=null) {
      url = url + `?authId=${authId}`;
    }

    return axios.get(url, data, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },
  getBatchCertificateSignatureRequests(token, authId) {
    return axios.get( `${baseURL}signatures/?authId=${authId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

  downloadEvidenceReport(id) {
    return HTTP.get(`evr/${id}/`, {
      responseType: "arraybuffer",
      headers: { Accept: "application/pdf" },
    });
  },
};

import axios from "axios";
import { baseURL, getAccessToken } from "../config/setting";

const FormsService = {

    getFormStatus(requestId, token = null, authId = null) {
        let url = `${baseURL}signatures/${requestId}/`
        let accessToken = getAccessToken();
        //Modificar.
        // url += `?authId=00000000T`;
        if (authId) {
        // if (token && authId) {
            url += `?authId=${authId}`;
            accessToken = token;
        }

        return new Promise((resolve) => {
            const response = axios.get(url, {
                headers: {
                  Authorization: `Token ${accessToken}`,
                  Accept: "application/json",
                },
            });

            resolve(response)
        })
    },



    getFormQuestionStructure(requestId, token = null, authId = null) {
        let url = `${baseURL}signatures/${requestId}/signerform/`
        let accessToken = getAccessToken();
        if (token && authId) {
            url += `?authId=${authId}`;
            accessToken = token;
        }

        return new Promise((resolve) => {
            const response = axios.get(url, {
                headers: {
                  Authorization: `Token ${accessToken}`,
                  Accept: "application/json",
                },
            });

            resolve(response)
        })
    },

    getFormStructure(requestId, token = null, authId = null) {
        let url = `${baseURL}signatures/${requestId}/signerform/`
        //let accessToken = token;
        if (token && authId) {
            url += `?authId=${authId}`;
         
        }

        return new Promise((resolve) => {
            const response = axios.get(url, {
                headers: {
                  Authorization: `Token ${token}`,
                  Accept: "application/json",
                },
            });

            resolve(response)
        })
    },
    
    postAnswers(requestId, payload, token = null, authId = null ) {
        let url = `${baseURL}signatures/${requestId}/signerform/`
        let accessToken = getAccessToken();
        if (token && authId) {
            url += `?authId=${authId}`;
            accessToken = token;
        }

        return new Promise((resolve, reject) => {
            axios.post(url, payload, {
                headers: {
                  Authorization: `Token ${accessToken}`,
                  Accept: "application/json",
                },
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });

        })
    }
}

export default FormsService
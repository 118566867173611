<template>
  <div class="top-nav-vbar">
    <v-app-bar app flat height="60" :color="appBarColor">
      <v-container class="py-0 fill-height pl-4 d-flex align-center justify-space-between">
        <v-toolbar-title :to="'/'" link>
          <v-img :src="appLogo ? appLogo : logo" height="33px" max-width="165px" alt="Logo" contain class="association-logo" />
        </v-toolbar-title>

        <div class="d-flex align-center" style="gap: 8px; flex-wrap: nowrap;">
          <!-- Idioma -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="d-flex align-center px-2"
                style="color: white; min-width: auto;"
              >
                <v-avatar size="20" class="mr-1">
                  <v-img :src="langImg" />
                </v-avatar>
                <span class="white--text text-body-2">{{ selectedLanguage }}</span>
              </v-btn>
            </template>

            <v-list dense class="pa-2">
              <v-list-item
                v-for="(item, lang) in languages"
                :key="lang"
                @click="setLocale(item)"
                class="language-option"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- Logout -->
          <v-btn icon color="white" @click="logout">
            <v-icon>mdi-power</v-icon>
          </v-btn>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>



<script>
export default {
	name: "app-bar",

	props: {
		appBarColor: {
			type: String,
			default: 'primary'
		},
		appLogo: {
			type: String,
			default: null
		},
	},

	data: function () {
		return {
			logo: require("../assets/logo.png"),
			languages: [
			{
					flag: "frIcon",
					language: "fr",
					locale: "fr-FR",
					title: "Française-FR"
				},
				{
					flag: "enIcon",
					language: "en",
					locale: "en-GB",
					title: "English-EN"
				},
				{
					flag: "esIcon",
					language: "es",
					locale: "es-ES",
					title: "Español-ES"
				},
				{
					flag: "caIcon",
					language: "ca",
					locale: "ca-ES",
					title: "Català-CA"
				}
			],
			selectedLanguage: "Español",
			defaultLang: this.$i18n.locale
		};
	},
	created() {
		this.setLanguage(this.defaultLang);
		this.langImg = this.esIcon;
	},

	methods: {
		setLanguage(locale) {
			this.languages.forEach(value => {
				if (value.language === locale) {
					this.selectedLanguage = value.title;
					this.setFlag(value.flag);
					this.$i18n.locale = value.language;
				}
			});
		},
		setLocale(item) {
			this.$i18n.locale = item.language;
			this.selectedLanguage = item.title;
			this.setFlag(item.flag);
		},

		setFlag(lang) {
			switch (lang) {
				case "frIcon":
					this.langImg = this.frIcon;
					break;
				case "enIcon":
					this.langImg = this.enIcon;
					break;
				case "esIcon":
					this.langImg = this.esIcon;
					break;

				case "caIcon":
					this.langImg = this.caIcon;
					break;

				default:
					break;
			}
		},
		logout() {
			localStorage.clear();
			this.$router.push('/login');
		}
	}
};
</script>

<style>
.top-nav-vbar .theme--light.v-sheet {
	color: rgba(0, 0, 0, 0.87);
	z-index: 99;
}
</style>
